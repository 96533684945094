// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.settings-btn {
    position: absolute;
    left: 10px;
    border: none;
    background: none;
    cursor: pointer;
}

.main-header__logo {
    margin: 0;
    text-align: center;
    flex-grow: 1;
    font-size: 20px;
}

.settings-wrapper{
    min-height: 610px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/AccountPage/AccountSettings/AccountSetting.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".main-header {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n}\n\n.settings-btn {\n    position: absolute;\n    left: 10px;\n    border: none;\n    background: none;\n    cursor: pointer;\n}\n\n.main-header__logo {\n    margin: 0;\n    text-align: center;\n    flex-grow: 1;\n    font-size: 20px;\n}\n\n.settings-wrapper{\n    min-height: 610px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
