// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-selector {
    position: relative;
    display: flex;
    gap: 20px;
    align-items: center;


    background-color: white;
    border-radius: 10px;
    padding: 1px 10px;
    width: 500px;
}

.survey-selector__icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    z-index: 2;
    opacity: 0.7;
    pointer-events: none;
    /* Чтобы иконка не мешала кликам по селекту */
}

.survey-selector__select {
    width: 100%;
}

/* Переопределение стилей CustomSelect для учета иконки */
.survey-selector .custom-select__trigger {
    padding-left: 44px;
    /* Отступ для иконки */
}

/* Состояния */
.survey-selector:hover .survey-selector__icon {
    opacity: 1;
}

.survey-selector--disabled .survey-selector__icon {
    opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/SurveysSelection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,mBAAmB;;;IAGnB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,oBAAoB;IACpB,6CAA6C;AACjD;;AAEA;IACI,WAAW;AACf;;AAEA,yDAAyD;AACzD;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA,cAAc;AACd;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".survey-selector {\n    position: relative;\n    display: flex;\n    gap: 20px;\n    align-items: center;\n\n\n    background-color: white;\n    border-radius: 10px;\n    padding: 1px 10px;\n    width: 500px;\n}\n\n.survey-selector__icon {\n    position: absolute;\n    left: 12px;\n    top: 50%;\n    transform: translateY(-50%);\n    width: 20px;\n    height: 20px;\n    z-index: 2;\n    opacity: 0.7;\n    pointer-events: none;\n    /* Чтобы иконка не мешала кликам по селекту */\n}\n\n.survey-selector__select {\n    width: 100%;\n}\n\n/* Переопределение стилей CustomSelect для учета иконки */\n.survey-selector .custom-select__trigger {\n    padding-left: 44px;\n    /* Отступ для иконки */\n}\n\n/* Состояния */\n.survey-selector:hover .survey-selector__icon {\n    opacity: 1;\n}\n\n.survey-selector--disabled .survey-selector__icon {\n    opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
