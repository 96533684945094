// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unknow-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 474px;
    text-align: center;
}

.unknow-pg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.unknow-pg__item {
    margin-bottom: 12px;
}

.unknow-pg__item:last-child {
    margin-bottom: 0;
}

.unknow-pg__item:first-child {
    color: #7553FF;
}

.unknow-pg__btn {
    background-color: #7553FF;
    color: white;
    border: none;
    border-radius: 22px;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.unknow-pg__btn:hover {
    background-color: #5a3dcb;
}

.unknow-pg__go-back {
    font-size: 18px;
    font-weight: 500;
    color: #7553FF;
    background-color: transparent;
    border: 1px solid transparent;
    margin-bottom: 10px;
    cursor: pointer;
    /* text-decoration: none; */
    transition: all 0.3s ease;
}

.unknow-pg__go-back:hover {
    /* text-decoration: underline; */
    color: #5a3dcb;
    transition: all 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/UnknowPage/UnknowPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,6BAA6B;IAC7B,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;IACf,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".unknow-main {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 474px;\n    text-align: center;\n}\n\n.unknow-pg {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n}\n\n.unknow-pg__item {\n    margin-bottom: 12px;\n}\n\n.unknow-pg__item:last-child {\n    margin-bottom: 0;\n}\n\n.unknow-pg__item:first-child {\n    color: #7553FF;\n}\n\n.unknow-pg__btn {\n    background-color: #7553FF;\n    color: white;\n    border: none;\n    border-radius: 22px;\n    padding: 12px 24px;\n    cursor: pointer;\n    font-size: 18px;\n    font-weight: 600;\n    transition: background-color 0.3s ease;\n}\n\n.unknow-pg__btn:hover {\n    background-color: #5a3dcb;\n}\n\n.unknow-pg__go-back {\n    font-size: 18px;\n    font-weight: 500;\n    color: #7553FF;\n    background-color: transparent;\n    border: 1px solid transparent;\n    margin-bottom: 10px;\n    cursor: pointer;\n    /* text-decoration: none; */\n    transition: all 0.3s ease;\n}\n\n.unknow-pg__go-back:hover {\n    /* text-decoration: underline; */\n    color: #5a3dcb;\n    transition: all 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
