// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
    position: relative;
    width: 100%;
}

.search-input {
    width: 100%;
    padding: 8px;
    border: transparent;
    border-color: transparent;
    /* border-radius: 10px; */
    box-sizing: border-box;
}

.search-input :focus {
    outline: none;
    border: none;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: auto;
}

.search-result {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.2s;
}

.search-result:last-child {
    border-bottom: none;
}

.search-result:hover {
    background-color: #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/SearchBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;;IAEX,iBAAiB;IACjB,wCAAwC;IACxC,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,iCAAiC;AACrC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".search-bar {\n    position: relative;\n    width: 100%;\n}\n\n.search-input {\n    width: 100%;\n    padding: 8px;\n    border: transparent;\n    border-color: transparent;\n    /* border-radius: 10px; */\n    box-sizing: border-box;\n}\n\n.search-input :focus {\n    outline: none;\n    border: none;\n}\n\n.search-results {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    width: 100%;\n\n    background: white;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    z-index: 10;\n    border-radius: 8px;\n    max-height: 200px;\n    overflow-y: auto;\n}\n\n.search-result {\n    padding: 10px;\n    cursor: pointer;\n    border-bottom: 1px solid #eee;\n    transition: background-color 0.2s;\n}\n\n.search-result:last-child {\n    border-bottom: none;\n}\n\n.search-result:hover {\n    background-color: #f0f0f0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
