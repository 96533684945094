// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.similarity-analytics__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: 20px;
}

.similarity-analytics__section {
    margin-bottom: 32px;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.similarity-analytics__title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: var(--text-primary);
}

.similarity-analytics__chart {
    height: 400px;
    width: 100%;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/ModelsPage/SimilarityPage/SimilarityPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".similarity-analytics__loader {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 300px;\n    font-size: 20px;\n}\n\n.similarity-analytics__section {\n    margin-bottom: 32px;\n    padding: 24px;\n    border-radius: 10px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.similarity-analytics__title {\n    margin-bottom: 20px;\n    font-size: 20px;\n    font-weight: 600;\n    color: var(--text-primary);\n}\n\n.similarity-analytics__chart {\n    height: 400px;\n    width: 100%;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
